// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vW6KibI5neaZtH96TWxK{position:absolute;right:-10px;bottom:-10px;width:70px;height:70px}.vW6KibI5neaZtH96TWxK img{position:absolute;top:0;left:0;right:0;bottom:0}.vW6KibI5neaZtH96TWxK>div{position:absolute;top:0;left:5px;right:5px;bottom:0;display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;color:#fff;font-size:.4rem;font-weight:bold;font-style:italic}.vW6KibI5neaZtH96TWxK.gjN2RcGZrRrL6oEJmLXi{width:140px;height:140px}.vW6KibI5neaZtH96TWxK.gjN2RcGZrRrL6oEJmLXi>div{left:10px;right:10px;font-size:.8rem}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/LimitedQuantityDateOverlay.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,0BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CAEF,0BACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAEF,2CACE,WAAA,CACA,YAAA,CACA,+CACE,SAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".overlay {\n  position: absolute;\n  right: -10px;\n  bottom: -10px;\n  width: 70px;\n  height: 70px;\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n  }\n  > div {\n    position: absolute;\n    top: 0;\n    left: 5px;\n    right:  5px;\n    bottom: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    color: #fff;\n    font-size: .4rem;\n    font-weight: bold;\n    font-style: italic;\n  }\n  &.large {\n    width: 140px;\n    height: 140px;\n    > div {\n      left: 10px;\n      right: 10px;\n      font-size: .8rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `vW6KibI5neaZtH96TWxK`,
	"large": `gjN2RcGZrRrL6oEJmLXi`
};
export default ___CSS_LOADER_EXPORT___;
